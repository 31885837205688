import * as React from "react"
import Layout from "../components/layout"
import HeroBanner from "../components/heroBanner"
import ProjectIntro from "../components/projectIntro"
import Gallery from "../components/gallery"
import Details from "../components/details"
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import Cta from "../components/cta"
import { graphql } from "gatsby"

const Project = ({ data }) => {
  var project = data.project
  return (
    <Layout>
      <HeroBanner title={project.title} image={project.featureImage} />
      <ProjectIntro project={project} /> 
      <Spacer className='m-hide'  />
      {(project.gallery.length ? 
        <Gallery gallery={project.gallery} /> :''
      )}
      <Details project={project} />
      <Cta />
    </Layout>
  )
}

export const Head = ({ data }) => <Seo title={data.project.title} />

export default Project

export const query = graphql`
  query ProjectQuery($id: String!) {
    project:datoCmsProject(id: { eq: $id }) {
      title
      builder
      category
      location
      introduction
      projectCaption
      introductionDetails {
        title
        content
      }
      gallery {
        gatsbyImageData
      }
      featureImage {
        gatsbyImageData
      }
      detailsIntro
      detailsImage {
        gatsbyImageData
      }
      firstImage {
        gatsbyImageData
      }
      secondaryCopy
      secondImage {
        gatsbyImageData
      }
      quote
      quoteAuthor
    }
  }
`
