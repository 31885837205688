import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Spacer from "../components/spacer"

const Details = ({project}) =>  {
	return (
		<div className="details p40">
			{( project.detailsIntro ?
			<>
				<div className='flex m-wrap'>
					<p className='m0 h4 m-mt20 m-mb40'>The Details</p>
					<div className='mla max-450 large'>
						{project.detailsIntro}
					</div>
				</div>
				<Spacer />
			</> : ''
			)}
			{( project.firstImage ?
				<>
				<div className='flex m-wrap'>
					<div className='w-50 m-100'>
						<GatsbyImage className="max-500 mla" image={project.firstImage?.gatsbyImageData} alt="Feature Image" />
					</div>
				</div>

				<Spacer className='m-hide' />
				</> : ''
			)}
			{( project.secondImage ?
				<>
				<div className='secondary-copy flex m-wrap m-mt40'>
					<div className='w-50 m-100 flex'>
						<div className='max-450 mta'>
							<p className='m0 large'>{project.secondaryCopy}</p>
						</div>
					</div>
					<div className='w-50 m-100 flex m-mt40'>
						<GatsbyImage className="max-600 mla" image={project.secondImage?.gatsbyImageData} alt="Feature Image" />
					</div>	
				</div>
				<Spacer />
				</>
			 : ''

			)}
			
			{( project.detailsImage ?
			<>
				<div className='flex'>
					<GatsbyImage className="max-900 ma" image={project.detailsImage?.gatsbyImageData} alt="Feature Image" />
				</div>
				<Spacer  />
			</>
			: ''
			)}
			{( project.quote ?
			<>
			<div className='quote'>
				<div className='max-800'>
					<p className='m0 h4'>{project.quote}</p>
					<p className='m0 mt20'>{project.quoteAuthor}</p>
				</div>
			</div>
			<Spacer />
			</> :
			''
			)}
		</div>
	)
}
	
export default Details
