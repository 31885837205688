import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { gsap } from "gsap"

const HeroBanner = ({ title, image }) => {
  useEffect(() => {
    gsap.fromTo(
      ".hero--banner .title span",
      { y: "105%", skewY: "5deg" },
      { y: 0, skewY: 0, duration: 1, delay: 0.25, ease: "Power3.easeOut" }
    )
  })
  return (
    <div className="hero--banner bg-grey flex light h-100 p40 pos-rel">
      <h1 className="title m0 mta mba text-center pos-rel z-2 overflow-hidden p5">
        <span className="inline" dangerouslySetInnerHTML={{ __html: title }} />
      </h1>
      <GatsbyImage className="bg-image" image={image?.gatsbyImageData} alt="Feature Image" />
    </div>
  )
}

export default HeroBanner
