import * as React from "react"

const ProfileImage = ({ project }) => {
  return (
  <div className="project--intro p40 mt40">
    <p className='h4 mb40 m0'>{project.category}</p>
    <p className='h1 m0 max-1200'>{project.projectCaption}</p>
    <div className='flex m-wrap mt80'>
      <div className='w-50 m-100 m-mb40'>
        <div className='max-450 large break-space'>
          {project.introduction}
        </div>
      </div>
      <div className='w-50 m-100 flex'>
        <div className='max-450 large w-100 mla m-mb40'>
          {project.introductionDetails.map((detail, index) => {
            return (
            <div className='pb10 pt10 bb1 b-grey flex' key={index}>
              <p className='op-50 m0'>{detail.title}</p>
              <p className='mla m0'>{detail.content}</p>
            </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
  )
}

export default ProfileImage
