import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation} from 'swiper';

import 'swiper/css';
  
const Gallery = ({gallery}) => {
	return (
  		<div className="gallery p40 bt1 m-bb1 m-black">
   			<Swiper className='mt40 mb40 m-m0'  modules={[Navigation]} spaceBetween={50}  speed={1000} slidesPerView={'auto'}  navigation={{ prevEl: '.gallery-nav.prev', nextEl: '.gallery-nav.next' }} freeMode={'true'}>
	    		{gallery.map((image, index) => {
	          	return (
	      		<SwiperSlide key={index}>
	            	<GatsbyImage className="inline gallery-image" image={image?.gatsbyImageData} alt="Feature Image" />
	            </SwiperSlide>
		         )})}
	        </Swiper>
	        <div className='flex'>
	        	<div className='gallery-navigation mla'>
	        		<div className='gallery-nav prev'/>
	        		<div className='gallery-nav next'/>
	        	</div>
	        </div>
  		</div>
	)
}

export default Gallery
